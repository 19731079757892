/* eslint-disable jsx-a11y/anchor-is-valid */
import { motion } from "framer-motion";
import { CSSProperties, ReactNode } from "react";
import styled from "styled-components";
import Link from "~/components/Link";
import { hSpacing } from "~/styles/mixins";

const MobileMenuClose = styled(motion.div)`
  z-index: ${(props) => props.theme.z.topBar - 2};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const MobileMenuWrapper = styled(motion.div)<{ mobileMenuOpen: boolean }>`
  z-index: ${(props) => props.theme.z.topBar - 1};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0; /* initial */
  padding-top: ${(props) => props.theme.sizes.topBarMobileHeight}rem;
  overflow: ${(props) => (props.mobileMenuOpen ? "auto" : "hidden")};
  background-color: ${(props) => props.theme.colors.light};
  ${hSpacing};
  ${MobileMenuClose} {
    display: ${(props) => (props.mobileMenuOpen ? "block" : "none")};
  }
`;

export const MobileSubMenu = styled.div`
  padding-top: ${(props) => props.theme.sizes.vSpacing * 1}rem;
  ${hSpacing};
`;

export const MobileSubMenuTitle = styled(motion.div)`
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  padding: 1rem 0;
  border-bottom: 1px solid #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const MobileSubMenuLinks = styled(motion.div)<{ isOpen?: boolean }>`
  overflow: hidden;
  max-height: ${(props) => (props.isOpen ? "500px" : "0")};
  transition: max-height 0.3s ease;
  padding-top: 0.5rem;
`;

const MobileSubMenuLinkWrapper = styled(motion.div)`
  padding: 0.75rem 0;
  font-size: 0.9rem;
  a {
    color: ${(props) => props.theme.colors.dark};
    text-decoration: none;
  }
`;

type MobileSubMenuLinkProps = {
  label: string;
  href?: string;
  as?: string;
  img: string;
};

export function MobileSubMenuLink({ label, href, as, img }: MobileSubMenuLinkProps) {
  const hrefAs = as || href;

  return (
    <MobileSubMenuLinkWrapper>
      <Link href={href} as={hrefAs}>
        <a>
          {img && <img src={img} alt="" />}
          <span>{label}</span>
          {href?.includes("tma-2-dj-wireless") && (
            <span
              style={{
                marginLeft: "6px",
                backgroundColor: "rgb(203, 255, 15)",
                padding: "4px 5px",
                fontWeight: "bold",
                fontSize: "85%",
                borderRadius: "2px",
                color: "#000",
              }}
            >
              New
            </span>
          )}
        </a>
      </Link>
    </MobileSubMenuLinkWrapper>
  );
}

const mobileMenuWrapperVariants = {
  closed: {
    height: 0,
    transition: {
      staggerChildren: 0.08,
      delayChildren: 0.1,
      ease: [0.21, 0, 0.21, 0.99],
    },
  },
  open: {
    height: "100%",
    transition: {
      staggerChildren: 0.08,
      delayChildren: 0.1,
      ease: [0.21, 0, 0.21, 0.99],
    },
  },
};

type Props = {
  children: ReactNode;
  mobileMenuOpen?: boolean;
  mobileMenuClose?: () => void;
  style: CSSProperties;
};

function MobileMenu({
  children,
  mobileMenuOpen = false,
  mobileMenuClose = () => {},
  style,
}: Props) {
  return (
    <MobileMenuWrapper
      variants={mobileMenuWrapperVariants}
      animate={mobileMenuOpen ? "open" : "closed"}
      mobileMenuOpen={mobileMenuOpen}
      style={style}
    >
      {/* <MobileMenuClose onTap={mobileMenuClose} /> */}
      {children}
    </MobileMenuWrapper>
  );
}

export default MobileMenu;
